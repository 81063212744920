:root {
    --color-dourado: #d39250;
    --carousel-transition-duration: 1.2;
}

html {
    overflow-x: hidden;
}

body {
    max-width: 100vw;
    overflow-x: hidden;
}

.slick-track {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex !important;
    flex-wrap: wrap;
    margin-left: -24px !important;
    margin-right: -24px !important;
}

.slick-track .slick-slide {
    flex: 0 0 auto;
    padding-left: 24px;
    padding-right: 24px;
}

.slick-dots li {
    height: 4px !important;
    width: 4px !important;
    margin-right: 8px !important;
    border-radius: 8px;
    background: rgb(0, 0, 0);
    transition: all 0.2s ease 0s;
}

.slick-dots li.slick-active {
    width: 24px !important;
}

.slick-dots button {
    display: none !important;
}

.slick-dots {
    display: flex !important
}

@media (min-width: 768px) {
    .section {
        height: 100vh;
    }

    .slick-track .slick-slide {
        width: 33.33333333% !important;
    }
}

.carousel-inner .carousel-item-end,
.carousel-inner .carousel-item-start { 
  transform: translateX(0);
}

#carousel .carousel-item .col-md-4 {
    padding-left: 50px;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
    background: none !important;
}

.controls .carousel-controls {
    position: relative;
    right: 20px;
    width: 77px;
    height: 25px;
}

.carousel-indicators {
    margin-left: 37px;
}

.carousel-indicators [data-bs-target] {
    background-color: #232323;
}

.carousel-indicators button {
    width: 5px !important;
    height: 4px !important;
    border-radius: 50% !important;
    opacity: 1 !important;
}

.carousel-indicators .active {
    width: 30px !important;
    height: 3px !important;
    border-radius: initial !important;
}

.parceiros-flex {
    display:flex;
    flex-direction: row;
    gap: 40px;
}
.parceiros-flex img {
    width: 105px;
} 

@media (max-width: 768px) {
    #carousel .item {
        position: relative;
        height: 410px;
    }

    #carousel .carousel-controls {
        display: none;
    }

    .carousel-item {
        width: 200%;
        margin-right: -200%;
        transition: transform 2s ease-in-out !important;
    }

    .carousel-item-start {
        z-index: -1;
    }

    .carousel-item-prev {
        z-index: 2;
    }

    #carousel .item {
        float: left;
        max-width: calc(50% - 60px);
        margin: 10px;
    }
    .parceiros-flex {
        overflow-x:scroll
    }

}

a, a:hover {
    text-decoration: none;
}

@-webkit-keyframes slideDown{0%{-webkit-transform:translateY(0px);-ms-transform:translateY(0px);transform:translateY(0px);}50%{-webkit-transform:translateY(-10px);-ms-transform:translateY(-10px);transform:translateY(-10px);}100%{-webkit-transform:translateY(0px);-ms-transform:translateY(0px);transform:translateY(0px);}}/*!sc*/
@keyframes slideDown{0%{-webkit-transform:translateY(0px);-ms-transform:translateY(0px);transform:translateY(0px);}50%{-webkit-transform:translateY(-10px);-ms-transform:translateY(-10px);transform:translateY(-10px);}100%{-webkit-transform:translateY(0px);-ms-transform:translateY(0px);transform:translateY(0px);}}

.animation-down {
    animation: 1s ease 0s infinite normal none running slideDown;
}

.text-center * {
    text-align: center !important;
}

.pointer {
    cursor: pointer;
}

.index-1 {
    z-index: 1;
}

.mt-40 {
    margin-top: 40px;
}

.mt-116 {
    margin-top: 116px;
}

.mb-8 {
    margin-bottom: 8px;
}

.mb-16 {
    margin-bottom: 16px;
}

.mb-24 {
    margin-bottom: 24px;
}

.mb-32 {
    margin-bottom: 32px;
}

.mb-48 {
    margin-bottom: 48px;
}

.mb-56 {
    margin-bottom: 56px;
}

.pt-24 {
    padding-top: 24px;
}

.pl-80 {
    padding-left: 80px;
}

.pr-80 {
    padding-right: 80px;
}

.scroll-animate {
    width: 100vw;
    height: 100vh;
    display: inline-block;
}

.zoom-out {
    transform: scale(.007) !important;
}

.zoom-in {
    transform: translateZ(0) scale(1) !important;
}

@media (max-width: 548px) {
    .pl-80 {
        padding-left: 20px;
    }
    
    .pr-80 {
        padding-right: 20px;
    }
}

.jota-InputText--focus, .jota-Checkbox-label, .jota-Button {
    border-color: transparent !important;
    outline: none !important;
}

.fw-500 .jota-Typography {
    font-weight: 500 !important;
    line-height: 36px !important;
}

.jota-Typography {
    font-style: normal;
    text-align: left;
    letter-spacing: normal;
    margin: 0;
    padding: 0;
    color: #000000;
    font-family: 'Archivo';
}

.pt-large {
    padding-top: 200px;
}

.pb-large {
    padding-bottom: 200px;
}

@media (max-width: 1440px) {
    .pt-large {
        padding-top: 100px;
    }
    
    .pb-large {
        padding-bottom: 100px;
    }
    .container {
        max-width: 1156px;
    }
}

@media (min-width: 1440px) {
    .container-fluid {
        max-width: 1440px;
    }
}

@media (max-width: 548px) {
    .pt-large {
        padding-top: 100px;
    }
    
    .pb-large {
        padding-bottom: 100px;
    }
}

.muted .jota-Typography {
    opacity: 0.64;
}

.header {
    background-color: #44c8ea;
}


.home {
    min-height: calc(100vh - 63.71px);
    background-color: #44c8ea;
    margin-bottom: 100vh;
}

.home .title .jota-Typography {
    letter-spacing: 0.5rem;
}

.home .box {
    flex: 1;
    border: 3px solid var(--color-dourado);
}

.home .box-sub {
    flex: 1;
    border-left: 3px solid var(--color-dourado);
    border-right: 3px solid var(--color-dourado);
}

.home .box-top {
    height: 100px;
    border-bottom: 3px solid var(--color-dourado);
}

.home .box-bottom {
    height: 100px;
    border-top: 3px solid var(--color-dourado);
}

.home.custom-ticket {
    margin-bottom: 0 !important
}

@media (max-width: 1440px) {
    .lettering-shape {
        max-width: 80%;
    }
}

@media (max-width: 548px) {
    .home .box {
        max-height: calc(100vh - 170px);
    }

    .home .box-center .jota-Typography {
        font-size: 12px !important;
        text-align: center !important;
        letter-spacing: 0.4rem;
    }
    .home .box-top, .home .box-bottom {
        height: 75px;
    }
}

@media (min-width: 549px) {
    .home .box-bottom .vector-2 .union-center {
        margin-left: 100px;
        margin-right: 100px
    }
}

.home .box-bottom .vector-2 .union-rotate {
    transform: rotate(180deg);
}

.home .box-top .vector-1 {
    position: absolute;
    bottom: -28px;
    padding: 0 100px;
    background-color: #44c8ea;
}

.home .box-center .vector-3 {
    position: absolute;
    bottom: -3px;
    padding: 3px 100px;
    background-color: #44c8ea;
    z-index: 1;
}

@media (max-width: 548px) {
    .home .box-top .vector-1 {
        padding: 0 40px;
    }
    .home .box-center .vector-3 {
        padding: 3px 40px;
    }
}

.home .box-center {
    flex: 1;
}

.home .box-center .bilhete {
    z-index: 2;
    background-color: #44c8ea;
}

.home .box-center .camada1 {
    position: absolute;
    bottom: 50px;
    right: 50px;
}

.home .card-title {
    margin-bottom: 16px;
}

.two {
    min-height: 100vh;
    background-color: #000000;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -1;
}

.two .star {
    margin-bottom: 64px;
    margin-top: 48px;
}

.three {
    min-height: 100vh;
    background-color: #fff;
    position: relative;
}

.three .max-width {
    max-width: 1440px;
    margin: auto;
}

@media (max-width: 1440px) { 
    .font-14 .jota-Typography {
        font-size: 14px !important;
    }
}

.three .img-left {
    min-height: 100vh;
    object-fit: cover;
}

@media (max-width: 548px) {
    .three .paragraph .jota-Typography {
        font-size: 37px !important;
    }
}

.three .title {
    margin-top: 16px;
    margin-bottom: 120px;
}

.four input:-webkit-autofill{
    -webkit-text-fill-color: #fff !important;
}

.four .bottom {
    position: absolute;
    bottom: 0;
    right: 0;
}

.four .left {
    position: absolute;
    left: 0;
}

.four, .six {
    min-height: 100vh;
    background-color: #000000;
    position: relative;
}

.four .jota-Typography {
    color: #fff !important;
    text-align: center !important;
}

.four .jota-InputText label {
    color: #fff;
    text-align: left;
}

.four .jota-InputText input {
    border-color: #fff !important;
}

.four .jota-InputText ::placeholder {
    color: #989898 !important;
}
      
.four .jota-InputText :-ms-input-placeholder {
    color: #989898 !important;
}
      
.four .jota-InputText ::-ms-input-placeholder {
    color: #989898 !important;
}

.four .send button, .four .send button:hover {
    background-color: #fff;
    color: #000;
}

.four .jota-InputText input {
    color: #fff !important;
}

.four .gap-2 {
    max-width: 400px;
    margin: 0 auto;
}

.four .btn-enviar, .four .title {
    margin-top: 40px;
}

.four .btn-enviar .jota-Button[aria-disabled='true'] {
    background-color: #fff;
}

.parceria {
    overflow-y: auto;
}

.success, .error, .six .parceria {
    z-index: 2;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.success, .error {
    z-index: 3;
}

.success .jota-Typography, .error .jota-Typography {
    color: #000000 !important;
}

.success {
    background-color: #d9bbfb;
}

.success .paragraph .jota-Typography {
    font-size: 24px !important;
    line-height: 36px !important;
    color: rgba(0, 0, 0, 0.64) !important;
    font-weight: 400 !important;
}

.error {
    background-color: #ffe3c2;
}

#canvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.five {
    min-height: 100vh;
    overflow: hidden;
    position: relative;
    background-color: #44c8ea;
}

@media (max-width: 548px) {
    .five {
        background-color: #fff;
    }
}

.five #bg-move {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background-color: #44c8ea;
    position: absolute;
    z-index: -1;
}

.five .title {
    margin-bottom: 48px;
}

.five .img {
    margin-bottom: 32px;
}

.five .subtitle {
    margin-bottom: 24px;
}

.six .jota-Typography {
    color: #fff !important;
}

.six .title .jota-Typography, .six .subtitle .jota-Typography {
    color: #000 !important;
    text-align: center !important;
}

.six .black .jota-Typography {
    color: #000 !important;
}

@media (max-width: 548px) {
    .six .black .jota-Typography {
        text-align: center;
    }
}

@media (min-width: 548px) {
    .six .box-blue .btn-action button {
        margin-right: 80px;
    }
}

.six .caption .jota-Typography {
    font-size: 14px !important;
    font-weight: 500 !important;
    letter-spacing: 0.2em;
    margin-bottom: 8px;
}

.six .box-blue {
    background-color: #44c8ea;
}

.six .box-blue .btn-rosa {
    margin-top: 40px;
}

.six .box-blue .logo-dss {
    margin-bottom: 40px;
}

.close {
    position: absolute;
    top: 20px;
    right: 30px;
    cursor: pointer;
}

.six .jota-InputText__helperBox, .mais-bilhetes .jota-InputText__helperBox {
    display: none !important;
}

.btn-rosa button {
    background-color: #f673ad !important;
    color: #000 !important;
    border-radius: 70px !important;
    min-width: 100px !important;
    padding: 0 16px !important;
}


/*  MEUS BILHETES */

.header-b {
    border-bottom: 1px solid #ccc;
}

.meus-bilhetes {
    min-height: calc(100vh - 72.71px);
    margin-top: 80px;
    margin-bottom: 80px;
}

.meus-bilhetes .grid {
    margin-left: -24px;
    margin-right: -24px;
}

.meus-bilhetes .grid .col-md-4 {
    padding-left: 24px;
    padding-right: 24px;
}

.card-bilhete {
    border: 2px dashed #ccc;
    background-color: #fff;
}

.jota-Modal {
    background-color: rgba(0,0,0,0.5) !important;
    z-index: 100;
    padding: 15px
}

@media (min-width: 768px) {
    .jota-Modal__shape {
        width: 624px !important;
    }
}

.drawer {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
}

.drawer .backdrop {
    background-color: rgba(0,0,0,0.5);
    flex: 1 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.drawer .card-drawer {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    max-width: 40%;
    height: 100vh;
    background-color: #fff;
    position: absolute;
    right: 0;
}

.jota-Modal__shape .jota-ButtonGroup__button {
    display: none !important;
}

.jota-Modal__shape .jota-Button::before {
    content: '';
    width: 19px;
    height: 19px;
    background-image: url('../img/icon-button.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-right: 10px;
}

@media (max-width: 548px) { 
    .jota-Modal__shape {
        height: calc(100vh - 30px) !important;
    }
    .drawer .card-drawer {
        max-width: 100vw;
    }
    .drawer .backdrop {
        display: none;
    }
}

.bilhete-edit {
    position: relative;
}

.bilhete-edit .bilhete-name {
    position: absolute;
    bottom: 1.8em;
    font-size: 20px;
    text-transform: uppercase;
}

.perguntas {
    min-height: 100vh;
    background-color: #44c8ea;
    position: relative;
}

.perguntas .bg-black * {
    color: #fff !important;
}

.perguntas .mais-bilhetes {
    z-index: 2;
    position: fixed;
    overflow-y: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.perguntas .mais-bilhetes .jota-TextArea__counter, .parceria .jota-TextArea__counter {
    display: none !important;
}

.perguntas .caption .jota-Typography {
    font-weight: 500 !important;
    letter-spacing: 0.2em !important;
}

#box-bilhete {
    width: 100%;
    min-height: 1px;
    background-image: url('../img/preview-bilhete.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.seta-down {
    position: absolute;
    top: -70px;
}

footer {
    position: relative;
    background-color: #fff;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}